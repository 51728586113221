import { render, staticRenderFns } from "./InstitutionsPanel.vue?vue&type=template&id=29b59278"
import script from "./InstitutionsPanel.vue?vue&type=script&lang=js"
export * from "./InstitutionsPanel.vue?vue&type=script&lang=js"
import style0 from "../assets/profile.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../assets/administration.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./InstitutionsPanel.vue?vue&type=style&index=2&id=29b59278&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports